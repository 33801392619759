import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, Flex } from '@aws-amplify/ui-react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

import { useState } from 'react';
import { usePlaidFunctions } from '../utilities/plaidUtilities';
import { PlaidLink } from 'react-plaid-link';
import Plaid from '../utilities/Plaid';

const pages = ['Home', 'Plaid', 'Blog'];
const settings = ['Logout', "Plaid", 'Link Account'];


export default function Layout() {
  const { route, signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
    context.user
  ]);

  const { connecting, token, handleGetToken, handleSuccess, setConnecting } = usePlaidFunctions();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // State to control the visibility of PlaidLink component
  const [showPlaidLink, setShowPlaidLink] = useState(false);

  // Function to handle setting button click
  const handleSettingClick = (setting) => {
    // Check if the clicked setting is "Logout"
    if (setting === "Logout") {
      // Perform logout action
      logOut();
    } else if (setting === "Login") {
      logIn()
    } else if (setting === "Plaid") {
      // Call the function to get Plaid token
      handleGetToken();
      // Set the state to show PlaidLink component
      setShowPlaidLink(true);
    } else {
      // Perform action for other settings, if needed
      console.log(`Clicked on ${setting}`);
    }
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };


  const appBarMenuButtonClick = (page) => {
    if (page === "Home") {
      // Perform home action
      home();
    } else {
      // Perform action for other page, if needed
      console.log(`Clicked on ${page}`);
    }
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const headerStyle = {
    color: '#00008B', // Replace with your desired text color
  };

  const navigate = useNavigate();

  function logOut() {
    signOut();
    navigate('/login');
  }

  function logIn() {
    navigate('/login');
  }

  function home() {
    navigate('/');
  }

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                page === "Plaid" ? (
                  <Plaid key={page} />
                ) : (
                  <Button
                    key={page}
                    onClick={() => appBarMenuButtonClick(page)}
                    style={{ marginY: 2, color: 'white', display: 'block' }} // Using style prop
                  >
                    {page}
                  </Button>
                )
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <Button key={setting} onClick={() => handleSettingClick(setting)} variant="outlined" style={{ margin: '0.5rem' }}>
                    {setting === "Login" && route === 'authenticated' ? "Logout" : setting}
                  </Button>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {token ? (
        <PlaidLink
          token={token}
          onSuccess={handleSuccess}
          onExit={() => setConnecting(false)}
        />
      ) : null
      }
      <Outlet />
    </>
  );
}