import { get, post } from 'aws-amplify/api';
import { ConsoleLogger } from 'aws-amplify/utils';
import { Button, Flex } from '@aws-amplify/ui-react';
import PlaidLink from './PlaidLink';
import { usePlaidFunctions } from './plaidUtilities';

const logger = new ConsoleLogger("Plaid");

export default function Plaid() {

  const { connecting, token, handleGetToken, handleSuccess, setConnecting} = usePlaidFunctions();

  return (
    <Flex>
      <Button
        variation="primary"
        isLoading={connecting}
        onClick={handleGetToken}
      >
        CONNECT WITH PLAID
      </Button>
      {token ? (
        <PlaidLink
          token={token}
          onSuccess={handleSuccess}
          onExit={() => setConnecting(false)}
        />
      ) : null}
    </Flex>
  );
}
