import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { getTransactions as GetTransactions } from '../../graphql/queries';
import { ConsoleLogger } from 'aws-amplify/utils';

const logger = new ConsoleLogger("dataFecthing");

const useDataFetching = (tableState, id) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [hasMorePages, setHasMorePages] = useState(false);
    const client = generateClient();

    const getTransactions = async (id, cursor = null) => {
        setIsLoading(true);
        try {
            const res = await client.graphql({
                query: GetTransactions,
                variables: { id, cursor }
            });
            const transactions = res.data.getTransactions.transactions;
            setTransactions(res.data.getTransactions.transactions);
            setRowCount((prevCount) => prevCount + transactions.length);
            if (res.data.getTransactions.cursor) {
                setNextToken(res.data.getTransactions.cursor);
                setHasMorePages(true);
            } else {
                setHasMorePages(false);
            }
            setIsError(false);
        } catch (err) {
            logger.error('Unable to fetch transactions', err);
            setIsError(true);
        }
        setIsLoading(false);
        setIsRefetching(false);
    };

    useEffect(() => {
        getTransactions(id);
    }, [ tableState.columnFilters, tableState.globalFilter, tableState.pagination.pageIndex, tableState.pagination.pageSize, tableState.sorting]);

    const handleLoadMore = async () => {
        setIsRefetching(true);
        await getTransactions(id, nextToken);
    };

    return {
        transactions,
        isLoading,
        isError,
        isRefetching,
        rowCount,
        loading,
        nextToken,
        hasMorePages,
        handleLoadMore,
    };
};

export default useDataFetching;
