import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Flex } from '@aws-amplify/ui-react';

import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ConsoleLogger } from 'aws-amplify/utils';
import { generateClient } from 'aws-amplify/api';
import { getAccounts as GetAccounts } from '../../graphql/queries';

import Accounts from '../Accounts';
import InstitutionSelectFieldComponent from '../InstitutionSelect';
import TransactionTable from '../MaterialReactTable';
import { usePlaidFunctions } from '../../utilities/plaidUtilities';

const logger = new ConsoleLogger("Dashboard");

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Dashboard() {
  const { id } = useParams();
  const client = generateClient();
  const { items } = usePlaidFunctions();

  const [accountsUpdated, setAccountsUpdated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);

  const updateAccounts = (accounts) => {
    const accountMap = accounts.reduce((acc, cur, idx) => {
      acc[cur.account_id] = cur;
      return acc;
    }, {});
    setAccounts(accountMap);
    setAccountsUpdated(true);
  }

  const getAccounts = async () => {
    setLoading(true);
    try {
      const res = await client.graphql({
        query: GetAccounts,
        variables: { id }
      });
      updateAccounts(res.data.getAccounts);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      logger.error('unable to get accounts', err);
    }
  }

  useEffect(() => {
    // Call updateAccounts on page load
    // Here you can fetch accounts data and then call updateAccounts
    // For example:
    // fetchAccountsData().then(accounts => updateAccounts(accounts));
    // updateAccounts([]);
    getAccounts();
  }, []); // Empty dependency array to run the effect only once on mount

  return (
    <Flex direction="column">
      <Toolbar />
      <InstitutionSelectFieldComponent inst_options={items} />
      <Flex>
        <Accounts id={id} />
      </Flex>
      {accountsUpdated && (
        <Flex direction="column">
          <TransactionTable id={id} accounts={accounts} />
        </Flex>
      )}
      <Copyright sx={{ pt: 4 }} />
    </Flex>
  );
}
