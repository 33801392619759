
import { ConsoleLogger } from 'aws-amplify/utils';
import { Flex } from '@aws-amplify/ui-react';
import Institutions from '../components/Institutions';
import InstitutionSelectFieldComponent from '../components/InstitutionSelect';
import { usePlaidFunctions } from '../utilities/plaidUtilities';

const logger = new ConsoleLogger("Protected");


export default function Protected() {

  const { items, chase_item, loaded } = usePlaidFunctions();

  return (
    <Flex direction="column">
      <InstitutionSelectFieldComponent inst_options={items} />
      {loaded && (
        <Flex direction="column">
          <Institutions institutions={items} />
        </Flex>
      )}
    </Flex>
  );
}
