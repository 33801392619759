import React from 'react';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Button } from '@mui/material';
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

// Define a configuration for CSV export
const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
});

const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
};

// Function to export all data
export const exportAllData = (data) => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
};

// Function to export all rows
export const exportAllRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
};

// Function to export page rows
export const exportPageRows = (rows, disabled) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
};

// Function to export selected rows
export const exportSelectedRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
};

// Button components for triggering export actions
export const ExportAllDataButton = ({ data, disabled }) => (
    <Button disabled={disabled} onClick={() => exportAllData(data)} startIcon={<FileDownloadIcon />}>
        Export All Data
    </Button>
);

export const ExportAllRowsButton = ({ rows, disabled }) => (
    <Button disabled={disabled} onClick={() => exportAllRows(rows)} startIcon={<FileDownloadIcon />}>
        Export All Rows
    </Button>
);

export const ExportPageRowsButton = ({ rows, disabled }) => (
    <Button disabled={disabled} onClick={() => exportPageRows(rows)} startIcon={<FileDownloadIcon />}>
        Export Page Rows
    </Button>
);

export const ExportSelectedRowsButton = ({ rows, disabled }) => (
    <Button disabled={disabled} onClick={() => exportSelectedRows(rows)} startIcon={<FileDownloadIcon />}>
        Export Selected Rows
    </Button>
);
