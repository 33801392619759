// plaidUtils.js
import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';
import { get, post } from 'aws-amplify/api';
import { ConsoleLogger } from 'aws-amplify/utils';
import { getItems as GetItems } from '../graphql/queries';

const logger = new ConsoleLogger("Plaid Utilitites");
const apiName = "plaidapi";

export const usePlaidFunctions = () => {
    const [connecting, setConnecting] = useState(false);
    const [token, setToken] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const [items, setItems] = useState([]);
    const [chase_item, setChaseItem] = useState([]);
    const client = generateClient();

    useEffect(() => {
        // Check if items are loaded and the component hasn't been loaded yet
        if (items && items.length && !loaded) {
            setLoaded(true); // Mark the component as loaded
        }
    }, [items, loaded]); // Specify the dependencies here

    const getItems = async () => {
        try {
            const res = await client.graphql({
                query: GetItems
            });
            logger.info(res);
            setItems(res.data.getItems.items);
            setChaseItem(items.find((item) => item.institution_name.includes("chase")));
        } catch (err) {
            logger.error('unable to get items', err);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    const handleGetToken = async () => {
        setConnecting(true);
        try {
            const { body } = await get({
                apiName,
                path: '/v1/tokens'
            }).response;
            const data = await body.json();
            logger.debug('GET /v1/tokens response:', data);
            setToken(data.link_token);
        } catch (err) {
            logger.error('unable to create link token:', err);
        }
    };

    const handleSuccess = async (public_token, metadata) => {
        try {
            const { body } = await post({
                apiName,
                path: '/v1/tokens',
                options: {
                    body: {
                        public_token,
                        metadata
                    },
                },
            }).response;
            const data = await body.text(); // returns an 202 response code with an empty body
            logger.debug('POST /v1/tokens response:', data);
            getItems();
            setConnecting(false);
        } catch (err) {
            logger.error('unable to exchange public token', err);
        }
    };

    return { items, client, connecting, token, handleGetToken, handleSuccess, setConnecting, chase_item, loaded};
};
