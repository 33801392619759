import { useState, useEffect } from 'react';

const useTableState = () => {
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 25,
    });

    const [tableState, setTableState] = useState(() => ({
        columnFilters,
        globalFilter,
        sorting,
        pagination,
    }));

    useEffect(() => {
        setTableState({
            columnFilters,
            globalFilter,
            sorting,
            pagination,
        });
    }, [columnFilters, globalFilter, sorting, pagination]);

    return {
        columnFilters,
        setColumnFilters,
        globalFilter,
        setGlobalFilter,
        sorting,
        setSorting,
        pagination,
        setPagination,
        tableState,
        setTableState
    };
};

export default useTableState;
