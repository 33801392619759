import { useMemo } from 'react';

import { useState } from 'react';
import { ConsoleLogger } from 'aws-amplify/utils';
import React from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import Currency from './Currency';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Tooltip } from '@mui/material';

import useDataFetching from './DashboardReactTable/dataFetching';
import useTableState from './DashboardReactTable/tableState';

//MRT Imports
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton,
} from 'material-react-table';

//Material UI Imports
import {
    Box,
    ListItemIcon,
    MenuItem,
    lighten,
} from '@mui/material';

//Icons Imports
import { AccountCircle, Send } from '@mui/icons-material';
import { ExportAllDataButton } from './DashboardReactTable/exportUtils';

//Mock Data
//import { data } from './makeData';
const logger = new ConsoleLogger("TransactionTable");

export default function TransactionTable({ id, accounts }) {
    //data and fetching state
    const [localAccounts, setLocalAccounts] = useState([])
    const { tableState } = useTableState();
    const { transactions, isError, rowCount, hasMorePages, handleLoadMore } = useDataFetching(tableState, id);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: "Name"
            },
            {

                accessorKey: 'amount', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                filterVariant: 'autocomplete',
                filterFn: 'between',
                header: 'Amount',
                size: 300,
                Cell: ({ cell, row }) => (

                    <Box textAlign="center" component="span" sx={(theme) =>
                    ({
                        backgroundColor:
                            cell.getValue() < 50_000
                                ? theme.palette.error.dark
                                : cell.getValue() >= 50_000 && cell.getValue() < 75_000
                                    ? theme.palette.warning.dark
                                    : theme.palette.success.dark,
                        borderRadius: '0.25rem', color: '#fff', maxWidth: '9ch', p: '0.25rem',
                    })}
                    >
                        <Currency amount={cell.getValue()} currency={row.iso_currency_code} />

                    </Box>
                ),
            },
            {
                accessorKey: 'date', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                filterVariant: 'autocomplete',
                header: 'Date',
                size: 300,
            }, {
                accessorKey: 'account_id', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
                enableClickToCopy: true,
                filterVariant: 'autocomplete',
                header: 'Account',
                size: 300,
                Cell: ({ cell }) => (
                    <span>
                        {accounts[cell.getValue()]?.name ? `${accounts[cell.getValue()].name} - ${accounts[cell.getValue()].mask}` : 'Account Not Found'}
                    </span>
                ),
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: transactions, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        rowCount: rowCount,
        muiTableContainerProps: { sx: { maxHeight: '600px' } },
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        enableGrouping: true,
        enableColumnPinning: true,
        enableFacetedValues: true,
        enableRowActions: true,
        enableRowSelection: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        initialState: { density: 'compact', showColumnFilters: true, showGlobalFilter: true },
        muiSearchTextFieldProps: {
            size: 'small',
            variant: 'outlined',
        },
        muiPaginationProps: {
            color: 'secondary',
            rowsPerPageOptions: [10, 25, 50, 100],
            shape: 'rounded',
            variant: 'outlined',
        },
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        renderDetailPanel: ({ row }) => (
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'left',}}>
                    {row.category}
            </Box>
        ),
        renderRowActionMenuItems: ({ closeMenu }) => [
            <MenuItem
                key={0}
                onClick={() => {
                    // View profile logic...
                    closeMenu();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <AccountCircle />
                </ListItemIcon>
                View Profile
            </MenuItem>,
            <MenuItem
                key={1}
                onClick={() => {
                    // Send email logic...
                    closeMenu();
                }}
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <Send />
                </ListItemIcon>
                Send Email
            </MenuItem>,
        ],
        renderTopToolbar: ({ table }) => {

            return (
                <Box sx={(theme) => ({
                    backgroundColor: lighten(theme.palette.background.default, 0.05),
                    display: 'flex',
                    gap: '0.5rem',
                    p: '8px',
                    justifyContent: 'space-between',
                })}
                >
                    <Box sx={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap', }}>
                        <ExportAllDataButton data={transactions} disabled={false} />
                    </Box>
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton disabled={!hasMorePages} onClick={handleLoadMore}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                        {/* import MRT sub-components */}
                        <MRT_GlobalFilterTextField table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                    </Box>
                </Box>
            );
        },
    });

    return (
        <MaterialReactTable table={table} accounts={localAccounts} />
    )
};
