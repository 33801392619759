import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Flex } from '@aws-amplify/ui-react';
import Dashboard from '../components/dashboard/Dashboard';

export default function Institution() {

  return (
    <Dashboard />
  );
}
