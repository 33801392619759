// YourSelectFieldComponent.js
import React, { useState, useEffect } from 'react';
import { SelectField } from '@aws-amplify/ui-react';
import { ConsoleLogger } from 'aws-amplify/utils';
import { useNavigate } from "react-router-dom";

const logger = new ConsoleLogger("InstitutionSelect");


const InstitutionSelectFieldComponent = ({inst_options}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [localOptions, setLocalOptions] = useState([]);

  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    
    const institution = inst_options.find(item => item.institution_name === event.target.value);

    // If foundElement is not null, it means an element matching the criteria was found
    if (institution) {
      // Do something with the found element  
      const link = `/institution/${institution.item_id}`;
      navigate(link);
    } else {
      console.log('No element found matching the criteria.');
    }
  };
 
  useEffect(() => {
    // Check if options is defined before mapping
    if (inst_options && inst_options.length > 0) {
        const transformedOptions = inst_options.map(item => (item.institution_name))
      setLocalOptions(transformedOptions);
    }
  }, [inst_options]); // Run whenever options change

  return (
    <div>
      <SelectField
        label="Institutions"
        placeholder="Select an Institution"
        value={selectedValue}
        options={localOptions}
        size='small'
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default InstitutionSelectFieldComponent;
